import './App.css';
import data from './results.json';
import Table from './Table';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {useMemo, useLayoutEffect, useRef} from 'react';

am4core.useTheme(am4themes_animated);

function App() {

  const stats = data['Stats'];
  const mat = data['Matrice'];
  const labels = ['Lucien', 'Jules P', 'Amaury', 'Paul', 'Jules S', 'Thomas'];
  const chart = useRef(null);

  const columns = useMemo(
  () => [
    {
      Header: "Data rôtissoire entière",
      columns: [
        {
          Header: "Name",
          accessor: "Name"
        },
        {
          Header: "Total posts",
          accessor: "Total posts"
        },
        {
          Header: "Posts/day",
          accessor: "Posts/day"
        },
        {
          Header: "Total likes sent",
          accessor: "Total likes sent"
        },
        {
          Header: "Total likes received",
          accessor: "Total likes received"
        },
        {
          Header: "Likes/post",
          accessor: "Likes/post"
        }
      ]
    }
  ],
  []);

  useLayoutEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.ChordDiagram);

    let data = [];
    for (let i=0; i<6; i++) {
      for (let j=0; j<6; j++) {
        data.push({"from": labels[i], "to": labels[j], "value": mat[j][i]});
      }
    }

    chart.data = data;

    chart.dataFields.fromName = "from";
    chart.dataFields.toName = "to";
    chart.dataFields.value = "value";

    var links = chart.links.template
    links.tooltipText = "[bold]{fromName}[] liked [bold]{value}[] posts sent by [bold]{toName}[]"
    chart.tooltip.label.textAlign = "middle"

    var hoverState = links.states.create("hover")
    hoverState.properties.fillOpacity = 0.7
    hoverState.properties.strokeOpacity = 0.7

    let label = chart.nodes.template.label;
    label.fontSize = 25;
    label.fill = am4core.color("#ffff");

    let nodeTemplate = chart.nodes.template
    nodeTemplate.showSystemTooltip = false
    nodeTemplate.propertyFields.fill = "color"
    nodeTemplate.tooltipText = "{name}"

    // when rolled over the node, make all the links rolled-over
    nodeTemplate.events.on("over", function (event) {
      let node = event.target
      node.outgoingDataItems.each(function (dataItem) {
        if (dataItem.toNode) {
          dataItem.link.isHover = true
          dataItem.toNode.label.isHover = true
        }
      })
      node.incomingDataItems.each(function (dataItem) {
        if (dataItem.fromNode) {
          dataItem.link.isHover = true
          dataItem.fromNode.label.isHover = true
        }
      })

      node.label.isHover = true
    })

    // when rolled out from the node, make all the links rolled-out
    nodeTemplate.events.on("out", function (event) {
      let node = event.target
      node.outgoingDataItems.each(function (dataItem) {
        if (dataItem.toNode) {
          dataItem.link.isHover = false
          dataItem.toNode.label.isHover = false
        }
      })
      node.incomingDataItems.each(function (dataItem) {
        if (dataItem.fromNode) {
          dataItem.link.isHover = false
          dataItem.fromNode.label.isHover = false
        }
      })

      node.label.isHover = false
    })

    chart.nodes.template.draggable = false

    return () => {
      chart.dispose();
    };
  });

  return (
    <div className="App">
      <header className="App-header">
        <img src="https://img.icons8.com/emoji/256/000000/chicken-emoji.png" className="App-logo" alt="logo" />
        <Table columns={columns} data={stats} />
        <div id="chartdiv" style={{ width: "100vw", height: "700px" }}></div>
      </header>
    </div>
  );
}

export default App;
